<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="modelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 10 }" @finish="onSubmit">
				<a-form-item label="发放用户类型" name="type" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.type" button-style="solid" @change="onTypeChange">
						<a-radio-button :value="1">全部用户</a-radio-button>
						<a-radio-button :value="2">满足会员等级用户</a-radio-button>
						<a-radio-button :value="3">指定用户</a-radio-button>
						<a-radio-button :value="4">指定手机号</a-radio-button>
					</a-radio-group>
				</a-form-item>
				
				<a-form-item v-if="modelRef.type === 2" label="选择会员等级">
					<a-form-item-rest>
					  <selectMember v-model:value="memberIdList" :selectItems="memberList" @change="(dataList) => {memberList = dataList}"></selectMember>
					</a-form-item-rest>
					<div style="margin-top: 10px; width: 700px;" v-if="memberList.length">
					  <a-table :pagination="false" :columns="memberColumns" :dataSource="memberList" rowKey="id"
					           :scroll="{ x: 450 }">
					    <template #bodyCell="{ column, record, index }">
					      <template v-if="column.key === 'action'">
					        <a-button type="link" @click="onMemberDelete(record, index)">删除</a-button>
					      </template>
					    </template>
					  </a-table>
					</div>
				</a-form-item>
				
				<a-form-item v-if="modelRef.type === 3" name="userIds" label="选择指定用户">
					<a-form-item-rest>
					  <selectBaseUser v-model:value="modelRef.userIds" :selectItems="userList" @change="(dataList) => {userList = dataList}"></selectBaseUser>
					</a-form-item-rest>
					<div style="margin-top: 10px; width: 700px;" v-if="userList.length">
					  <a-table :pagination="false" :columns="userColumns" :dataSource="userList" rowKey="id"
					           :scroll="{ x: 450 }">
					    <template #bodyCell="{ column, record, index }">
					      <template v-if="column.key === 'cardType'">
					        <div v-if="record.cardType === 1">普通卡</div>
					        <div v-if="record.cardType === 2">主题卡</div>
					        <div v-if="record.cardType === 3">礼品卡</div>
					      </template>
					      <template v-if="column.key === 'action'">
					        <a-button type="link" @click="onUserDelete(record, index)">删除</a-button>
					      </template>
					    </template>
					  </a-table>
					</div>
				</a-form-item>
				
				<a-form-item v-if="modelRef.type === 4" label="用户手机号" name="phone" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.phone" placeholder="请输入用户手机号"></a-input>
				</a-form-item>
				
				<a-form-item label="会员有效期" name="month" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number v-model:value="modelRef.month" :min="1" :precision="0" placeholder="请输入"></a-input-number> 月
				</a-form-item>
				
				<a-form-item>
					<div style="margin-left: 400px;">
						<a-button type="primary" html-type="submit">立 即 发 放</a-button>
					</div>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import selectMember from "@/components/select/selectMember";
	import selectBaseUser from "@/components/select/selectBaseUser";
	import { grantMember } from '@/service/modules/member.js';
	export default {
		components: { selectMember, selectBaseUser },
		data() {
			return {
				loading: false,
				modelRef: {
					type: 1,
					userIds: []
				},
				memberIdList: [],
				memberList: [],
				memberColumns: [{
				  title: '会员等级',
				  dataIndex: 'level',
				}, {
				  title: '所需积分',
				  dataIndex: 'needGrowth',
				}, {
				  title: '操作',
				  key: 'action',
				}],
				userList: [],
				userColumns: [{
				  title: '用户名称',
				  dataIndex: 'nickname'
				}, {
				  title: '用户电话',
				  dataIndex: 'phone'
				}, {
				  title: '操作',
				  key: 'action',
				}],
			}
		},
		methods: {
			onSubmit() {
				this.$confirm({
					title: '提示',
					content: '确定发放特色会员吗？',
					onOk: async ()=> {
						
					}
				})
			},
			onTypeChange() {
				this.$refs.formRef.resetFields();
				this.modelRef.userIds = [];
				this.userList = [];
			},
			onMemberDelete(item, index) {
			  let i = this.memberIdList.indexOf(item.id);
			  this.memberIdList.splice(i, 1);
			  this.memberList.splice(index, 1);
			},
			onUserDelete(item, index) {
			  let i = this.userIdList.indexOf(item.id);
			  this.userIdList.splice(i, 1);
			  this.userList.splice(index, 1);
			},
		}
	}
</script>

<style scoped>
</style>